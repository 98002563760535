import type { Announcement } from '@/types/Announcement';

export interface PublishOptions {
    no_flash: boolean;
    is_crypto_premium: boolean;
    is_guest_post_premium: boolean;
    is_premium: boolean;
    is_financial: boolean;
    is_msn: boolean;
    publish_blog_post: boolean;
    publish_infographic: boolean;
    publish_podcast: boolean;
    publish_press_release: boolean;
    publish_slideshow_slideshare: boolean;
    publish_slideshow: boolean;
    min_distribution_time: string;
    publish_video_public: boolean;
    publish_video_ubc: boolean;
    publish_video_vimeo: boolean;
    publish_video_youtube: boolean;
    publish_video: boolean;
    use_user_slideshare_account: boolean;
    use_user_vimeo_account: boolean;
    use_user_youtube_account: boolean;
    upgrade_presscable_credits: null | number;
    has_upgraded: boolean;
}

export interface PublishableModules {
    press_release: boolean;
    blog_post: boolean;
    podcast: boolean;
    infographic: boolean;
    slideshow: boolean;
    video: boolean;
}

export type PublishData = {
    announcement: Announcement;
    publishable: boolean;
    hasCryptoPremiumCredits: boolean;
    hasDistributionCredits: boolean;
    hasPremiumCredits: boolean;
    hasFinancialCredits: boolean;
    hasMsnCredits: boolean;
    hasPressCableCredits: boolean;
    guestPostPremiumCredits: number;
    isFirstFinancialAmp: boolean;
    hasManyCompanies: boolean;
    userSlideshareUsername: null | string;
    userOauths: AppOAuth[];
    guestPostUrl: string;
    hasPublishedGuestPost: boolean;
    isFinancial: boolean;
    isMsn: boolean;
};

export type AppOAuth = {
    id: string | number;
    name: string;
    typeName: string;
};

export enum UpgradeCreditsAction {
    DontUpgrade = 1,
    UpgradeOne = 2,
    UpgradeAll = 3
}

export enum UseCreditsAction {
    UseAmpiFire = 1,
    UsePressCable = 2
}
