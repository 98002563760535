import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pa-0"},[_c(VDivider,{staticClass:"mb-6"}),_c(VRow,[_c(VCol,[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Slideshow Settings")]),_c(VRow,{staticClass:"align-end mt-0"},[_c(VCol,{staticClass:"pt-0",attrs:{"sm":"6","md":"4"}},[_c(VSwitch,{attrs:{"inset":"","label":"Distribute on Slideshare","hide-details":""},model:{value:(_vm.settings.use_user_slideshare_account),callback:function ($$v) {_vm.$set(_vm.settings, "use_user_slideshare_account", $$v)},expression:"settings.use_user_slideshare_account"}})],1),_c(VCol,{staticClass:"pt-0",attrs:{"sm":"6","md":"4"}},[(_vm.settings.use_user_slideshare_account)?_c(VChip,{staticClass:"pr-2",attrs:{"small":"","close":"","color":"faded-blue","close-icon":_vm.service.user ? 'pen' : 'plus-circle'},on:{"click:close":_vm.connectApp}},[_vm._v(" "+_vm._s(_vm.service.user || 'Add Slideshare Account')+" ")]):_vm._e(),(_vm.settings.use_user_slideshare_account)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.service.loading},on:{"click":function($event){$event.preventDefault();return _vm.refreshApp.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("fas fa-sync")])],1)]}}],null,false,1533529477)},[_c('span',[_vm._v("Refresh Connected Slideshare Account")])]):_vm._e()],1),_c('connect-slideshare',{on:{"connected":_vm.refreshApp}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }