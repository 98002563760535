<template>
    <v-container class="pa-0">
        <v-divider class="mb-6" />
        <v-row>
            <v-col>
                <label class="font-weight-bold">Video Settings</label>
                <v-checkbox
                    v-model="settings.publish_video_public"
                    label="Make video available for anyone to view"
                    class="mb-3"
                    hide-details
                />
                <v-row class="align-end">
                    <v-col cols="6" sm="4" class="pt-0">
                        <v-checkbox
                            v-model="settings.publish_video_youtube"
                            label="Distribute on Youtube"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="6" sm="4" class="pt-0">
                        <v-switch
                            v-if="settings.publish_video_youtube"
                            v-model="settings.use_user_youtube_account"
                            inset
                            label="Use my own account"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-0">
                        <v-chip
                            v-if="
                                settings.use_user_youtube_account &&
                                settings.publish_video_youtube
                            "
                            class="pr-2"
                            small
                            close
                            color="faded-blue"
                            :close-icon="
                                services.youtube.user ? 'pen' : 'plus-circle'
                            "
                            @click:close="connectApp('youtube')"
                        >
                            {{
                                services.youtube.user
                                    ? services.youtube.user.name
                                    : 'Add YouTube Account'
                            }}
                        </v-chip>
                        <v-tooltip
                            v-if="
                                settings.use_user_youtube_account &&
                                settings.publish_video_youtube
                            "
                            bottom
                        >
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    :loading="services.youtube.loading"
                                    v-on="on"
                                    @click.prevent="refreshApp('youtube')"
                                >
                                    <v-icon small>sync</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Connected YouTube Account</span>
                        </v-tooltip>
                    </v-col>
                    <connect-youtube @connected="refreshApp('youtube')" />
                </v-row>
                <v-row class="align-end mt-0">
                    <v-col cols="6" sm="4" class="pt-0">
                        <v-checkbox
                            v-model="settings.publish_video_vimeo"
                            label="Distribute to Vimeo"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="6" sm="4" class="pt-0">
                        <v-switch
                            v-if="settings.publish_video_vimeo"
                            v-model="settings.use_user_vimeo_account"
                            inset
                            label="Use my own account"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12" sm="4" class="pt-0">
                        <v-chip
                            v-if="
                                settings.publish_video_vimeo &&
                                settings.use_user_vimeo_account
                            "
                            class="pr-2"
                            small
                            close
                            color="faded-blue"
                            :close-icon="
                                services.vimeo.user ? 'pen' : 'plus-circle'
                            "
                            @click:close="connectApp('vimeo')"
                        >
                            {{
                                services.vimeo.user
                                    ? services.vimeo.user.name
                                    : 'Add Vimeo Account'
                            }}
                        </v-chip>
                        <v-tooltip
                            v-if="
                                settings.publish_video_vimeo &&
                                settings.use_user_vimeo_account
                            "
                            bottom
                        >
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    :loading="services.vimeo.loading"
                                    v-on="on"
                                    @click.prevent="refreshApp('vimeo')"
                                >
                                    <v-icon small>sync</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Connected Vimeo Account</span>
                        </v-tooltip>
                    </v-col>
                    <connect-vimeo @connected="refreshApp('vimeo')" />
                </v-row>
                <v-checkbox
                    v-model="settings.publish_video_ubc"
                    label="Distribute to UBC News World"
                    hide-details
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';

import { ConnectVimeo, ConnectYoutube } from '@/components/ConnectedApps';

import type { AppOAuth } from '..';

type Services = {
    youtube: {
        user: null | AppOAuth;
        loading: boolean;
    };
    vimeo: {
        user: null | AppOAuth;
        loading: boolean;
    };
};

const AmpModuleVideoProps = Vue.extend({
    name: 'AmpModuleVideo',
    props: {
        oauths: {
            type: Array as PropType<AppOAuth[]>,
            default() {
                return [];
            }
        },
        publish: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component<AmpModuleVideo>({
    components: {
        ConnectYoutube,
        ConnectVimeo
    }
})
export default class AmpModuleVideo extends AmpModuleVideoProps {
    settings = {
        publish_video_public: true,
        publish_video_youtube: true,
        publish_video_vimeo: true,
        publish_video_ubc: true,
        use_user_youtube_account: false,
        use_user_vimeo_account: false
    };

    services: Services = {
        youtube: {
            user: null,
            loading: false
        },
        vimeo: {
            user: null,
            loading: false
        }
    };

    @Watch('oauths')
    onOauthsChange() {
        this.setUserOauths(this.oauths);
    }

    @Watch('settings', { deep: true })
    onSettingsChange() {
        this.$emit('change', this.settings);
    }

    @Watch('premium')
    onPremiumChange() {
        this.updateSettings();
    }

    mounted() {
        this.updateSettings();
    }

    updateSettings() {
        this.settings.publish_video_public = this.publish;
        this.settings.publish_video_youtube = this.publish;
        this.settings.publish_video_vimeo = this.publish;
        this.settings.publish_video_ubc = this.publish;

        if (!this.publish) {
            this.settings.use_user_youtube_account = false;
            this.settings.use_user_vimeo_account = false;
        }
    }

    connectApp(app: keyof Services) {
        this.$store.dispatch('modal/open', `connect-${app}`);
    }

    refreshApp(app: keyof Services) {
        this.services[app].loading = true;

        this.$http
            .get('/connected_apps/')
            .then(({ data }) => {
                this.setUserOauths(data.data.userOauths);
            })
            .finally(() => {
                this.services[app].loading = false;
            });
    }

    setUserOauths(userOauths: AppOAuth[]) {
        this.services.youtube.user =
            userOauths.find(oauth => oauth.typeName === 'YouTube') || null;

        this.services.vimeo.user =
            userOauths.find(oauth => oauth.typeName === 'Vimeo') || null;

        if (this.services.youtube.user) {
            this.settings.use_user_youtube_account = true;
        }

        if (this.services.vimeo.user) {
            this.settings.use_user_vimeo_account = true;
        }
    }
}
</script>
