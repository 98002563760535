<template>
    <v-container class="pa-0">
        <v-divider class="mb-6" />
        <v-row>
            <v-col>
                <label class="font-weight-bold">Slideshow Settings</label>
                <v-row class="align-end mt-0">
                    <v-col sm="6" md="4" class="pt-0">
                        <v-switch
                            v-model="settings.use_user_slideshare_account"
                            inset
                            label="Distribute on Slideshare"
                            hide-details
                        />
                    </v-col>
                    <v-col sm="6" md="4" class="pt-0">
                        <v-chip
                            v-if="settings.use_user_slideshare_account"
                            class="pr-2"
                            small
                            close
                            color="faded-blue"
                            :close-icon="service.user ? 'pen' : 'plus-circle'"
                            @click:close="connectApp"
                        >
                            {{ service.user || 'Add Slideshare Account' }}
                        </v-chip>
                        <v-tooltip
                            v-if="settings.use_user_slideshare_account"
                            bottom
                        >
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    :loading="service.loading"
                                    v-on="on"
                                    @click.prevent="refreshApp"
                                >
                                    <v-icon small>fas fa-sync</v-icon>
                                </v-btn>
                            </template>
                            <span>Refresh Connected Slideshare Account</span>
                        </v-tooltip>
                    </v-col>
                    <connect-slideshare @connected="refreshApp" />
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ConnectSlideshare } from '@/components/ConnectedApps';

const AmpModuleSlideshowProps = Vue.extend({
    name: 'AmpModuleSlideshow',
    props: {
        slideshareUser: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component<AmpModuleSlideshow>({
    components: {
        ConnectSlideshare
    },
    watch: {
        settings: {
            handler() {
                this.$emit('change', this.settings);
            },
            deep: true
        }
    }
})
export default class AmpModuleSlideshow extends AmpModuleSlideshowProps {
    service = {
        user: this.slideshareUser,
        loading: false
    };

    settings = {
        use_user_slideshare_account: !!this.service.user
    };

    connectApp() {
        this.$store.dispatch('modal/open', 'connect-slideshare');
    }

    refreshApp() {
        this.service.loading = true;

        this.$http
            .get('/slideshows/slideshare_validation')
            .then(({ data }) => {
                this.service.user = data.username;
            })
            .finally(() => {
                this.service.loading = false;
            });
    }
}
</script>
