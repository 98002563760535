<template>
    <modal :id="id" title="Authorize Vimeo">
        <template #default>
            <p>
                We require only the permission to upload and edit videos. You
                can always revoke access to your account by visiting
                <a target="_blank" href="https://vimeo.com/settings/apps">
                    https://vimeo.com/settings/apps
                </a>
                , and removing
                <strong>UBC News</strong>
                from the list.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="authorize"
            >
                Authorize Vimeo Application
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';

@Component({
    components: {
        Modal
    }
})
export default class ConnectVimeo extends Vue {
    id = 'connect-vimeo';

    popup: Window | null = null;

    authorize() {
        this.$http
            .get('/connected_apps/authorize_vimeo_api')
            .then(({ data }) => {
                this.openLoginWindow(data.data.loginUrl);
            })
            .catch(() => {
                this.$store.dispatch(
                    'notification/error',
                    'Unable to get Vimeo authorization URL. Please try again later or contact support.'
                );
            });
    }

    openLoginWindow(url: string) {
        if (!this.popup || this.popup.closed) {
            this.popup = window.open(
                url,
                'Connect Vimeo Account',
                'height=1000,width=800'
            );
        } else {
            this.popup.focus();
        }

        const timer = setInterval(() => {
            if (this.popup?.closed) {
                clearInterval(timer);

                this.$emit('connected');

                this.close();
            }
        }, 1000);
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
