<template>
    <modal :id="id" title="Authorize Slideshare" @close="reset">
        <template #default>
            <a-form ref="form" :submit="authorize" :disabled="isLoading">
                <p>Enter your Slideshare credentials</p>
                <a-text-input
                    v-model="username"
                    label="Username"
                    rules="required"
                />
                <a-text-input
                    v-model="password"
                    label="Password"
                    vid="password"
                    rules="required"
                    :type="passwordInputType"
                    :append-icon="passwordInputIcon"
                    @click:append="toggle"
                />
                <v-container class="pa-0">
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-btn
                                text
                                :block="$vuetify.breakpoint.smAndDown"
                                @click="close"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="6" class="text-right">
                            <v-btn
                                type="submit"
                                color="primary"
                                :block="$vuetify.breakpoint.smAndDown"
                                :loading="isLoading"
                            >
                                Authorize Slideshare Application
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </a-form>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { AForm } from '@/components/AForm';
import { Modal } from '@/components/Modal';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

@Component({
    components: {
        Modal,
        AForm,
        ATextInput
    }
})
export default class ConnectSlideshare extends Vue {
    $refs!: {
        form: InstanceType<typeof AForm>;
    };

    id = 'connect-slideshare';

    controller: AbortController | null = null;

    username = '';
    password = '';

    isLoading = false;

    isPasswordVisible = false;

    authorize() {
        this.isLoading = true;

        this.controller = new AbortController();

        this.$http
            .post(
                '/slideshows/slideshare_validation',
                {
                    username: this.username,
                    password: this.password
                },
                {
                    signal: this.controller.signal
                }
            )
            .then(({ data }) => {
                if (!data.meta.success) {
                    this.$store.dispatch(
                        'notification/error',
                        data.meta.message
                    );

                    return;
                }

                this.$store.dispatch(
                    'notification/success',
                    'Slideshare account connected successfully'
                );

                this.$emit('connected');

                this.close();
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to get authorize Slideshare account. Please try again later or contact support.'
                    );
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    close() {
        this.reset();

        this.$store.dispatch('modal/close', this.id);
    }

    get passwordInputType() {
        return this.isPasswordVisible ? 'text' : 'password';
    }

    get passwordInputIcon() {
        return this.isPasswordVisible ? 'eye-slash' : 'eye';
    }

    toggle() {
        this.isPasswordVisible = !this.isPasswordVisible;
    }

    reset() {
        if (this.controller) {
            this.controller.abort();
            this.controller = null;
        }

        this.username = '';
        this.password = '';

        this.$refs.form.reset(false);
    }
}
</script>
