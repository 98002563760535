<template>
    <div>
        <a-alert v-if="showReviewModuleMessage" type="warning" class="my-6">
            <h3 class="mb-2">Ready to publish?</h3>
            <p class="mb-0 text-body-2">
                The selected modules below are ready for action and will be
                distributed if you proceed. Want to publish a module that’s not
                selected? Click the module link and resolve errors before
                proceeding to the next step.
            </p>
        </a-alert>
        <v-card
            class="amp-publish mb-2 mt-4"
            :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
        >
            <v-container>
                <v-row>
                    <v-col>
                        <v-card-title class="pa-0 pb-4">
                            Publish Amp
                            <v-spacer />
                            <v-switch
                                v-if="hasNecessaryCredits"
                                v-model="useAdvanced"
                                class="ma-0"
                                hide-details
                                inset
                                label="Advanced Settings"
                            />
                        </v-card-title>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider class="mt-n6 mb-6" />
                        <amp-details
                            :loading="isLoading"
                            :announcement="announcement"
                        />
                    </v-col>
                </v-row>
            </v-container>
            <v-container v-if="isLoading">
                <v-skeleton-loader
                    loading
                    type="article"
                    class="pa-0"
                    :class="{
                        'ml-n3': $vuetify.breakpoint.smAndDown,
                        'ml-n4': $vuetify.breakpoint.mdAndUp
                    }"
                />
            </v-container>
            <v-container v-else>
                <v-row>
                    <!-- we need an empty row here for smooth animation -->
                </v-row>
                <v-expand-transition>
                    <v-row v-show="useAdvanced && hasNecessaryCredits">
                        <v-col cols="12">
                            <date-selector
                                :announcement="announcement"
                                @change="updatePublishDate"
                            />
                        </v-col>
                        <v-col cols="12">
                            <amp-modules
                                :announcement="announcement"
                                :publishable="publishable"
                                @change="updatePublishOptions"
                            />
                        </v-col>
                        <v-expand-transition>
                            <v-col
                                v-if="videoPr"
                                v-show="options.publish_video"
                                cols="12"
                            >
                                <amp-module-video
                                    :oauths="oauths"
                                    :publish="options.publish_video"
                                    @change="updateOptions"
                                />
                            </v-col>
                        </v-expand-transition>
                        <v-expand-transition>
                            <v-col
                                v-if="slideshow"
                                v-show="options.publish_slideshow"
                                cols="12"
                            >
                                <amp-module-slideshow
                                    :slideshare-user="slideshareUser"
                                    @change="updateOptions"
                                />
                            </v-col>
                        </v-expand-transition>
                    </v-row>
                </v-expand-transition>
                <v-row>
                    <v-col>
                        <v-divider class="mb-6" />
                        <amp-guest-post-premium
                            :processing-payment="isProcessingPayment"
                            :guest-post-url="guestPostUrl"
                            :is-guest-post-premium="
                                options.is_guest_post_premium
                            "
                            :has-published-guest-post="hasPublishedGuestPost"
                            :guest-post-credits-count="guestPostPremiumCredits"
                            @change="updateOptions"
                            @validate="setGuestPostUrlValid"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider class="mb-6" />
                        <amp-premium
                            :announcement="announcement"
                            :processing-payment="isProcessingPayment"
                            :has-premium-credits="hasPremiumCredits"
                            :is-premium="options.is_premium"
                            @change="updateOptions"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider class="mb-6" />
                        <amp-financial
                            :announcement="announcement"
                            :has-financial-credits="hasFinancialCredits"
                            :has-many-companies="hasManyCompanies"
                            :is-first-financial-amp="isFirstFinancialAmp"
                            :is-financial="options.is_financial"
                            :processing-payment="isProcessingPayment"
                            @change="updateFinancial"
                            @validate="setLoaValid"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-divider class="mb-6" />
                        <amp-msn
                            :announcement="announcement"
                            :has-msn-credits="hasMsnCredits"
                            :is-msn="options.is_msn"
                            :processing-payment="isProcessingPayment"
                            @change="updateOptions"
                        />
                    </v-col>
                </v-row>
                <v-row v-if="isCryptoCategory">
                    <v-col>
                        <v-divider class="mb-6" />
                        <amp-crypto-premium
                            :announcement="announcement"
                            :processing-payment="isProcessingPayment"
                            :is-checked="options.is_crypto_premium"
                            @change="updateOptions"
                        />
                    </v-col>
                </v-row>
                <v-expand-transition @leave="onTermsCollapse">
                    <v-row v-if="needsCheckout || hasNecessaryCredits">
                        <v-col>
                            <v-divider class="mb-6" />
                            <amp-terms
                                :announcement="announcement"
                                :has-error="tosError"
                                @accepted="setTermsAccepted"
                            />
                        </v-col>
                    </v-row>
                </v-expand-transition>
                <v-row>
                    <v-col cols="12" class="d-flex mt-6">
                        <v-btn v-if="!isProcessingPayment" @click="review">
                            Review Amp
                        </v-btn>
                        <v-spacer />
                        <span
                            v-if="!hasNecessaryCredits && !isProcessingPayment"
                            class="primary--text d-flex align-center px-3"
                        >
                            You will be prompted to purchase credits before
                            submission
                        </span>
                        <span
                            v-if="isProcessingPayment"
                            class="primary--text d-flex align-center px-3"
                        >
                            This page will update automatically once payment has
                            been received
                        </span>
                        <v-progress-circular
                            v-if="isProcessingPayment"
                            indeterminate
                            color="primary"
                            class="mr-4"
                        />
                        <v-btn
                            v-if="needsCheckout"
                            color="primary"
                            :loading="isPublishing"
                            :disabled="!hasValidInput"
                            @click="process"
                        >
                            Checkout
                        </v-btn>
                        <v-btn
                            v-if="isPublishable"
                            color="primary"
                            :loading="isPublishing"
                            @click="process"
                        >
                            Publish
                        </v-btn>
                    </v-col>
                </v-row>

                <authorization-mail-sent
                    v-if="isFirstFinancialAmpAndNoPending"
                    :announcement="announcement"
                    @confirm="confirmAuthorization"
                />

                <upgrade-credit-dialog
                    v-if="hasUpgradableCredits"
                    :has-both-credits="hasAmpifireAndPresscableCredits"
                    @action="upgradeCredits"
                    @close="upgradeCreditsContinue"
                />

                <use-credit-dialog
                    v-if="hasAmpifireAndPresscableCredits"
                    @action="useCredits"
                    @close="useCreditsContinue"
                />
            </v-container>
        </v-card>
    </div>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import { formatDate, reduceErrors } from '@/utils/helpers';

import { Endpoint } from '@/mixins';

import { StatusChip } from '@/components/StatusChip';

import AmpDetails from './AmpDetails.vue';
import AmpTerms from './AmpTerms.vue';
import AmpModules from './AmpModules/AmpModules.vue';
import AmpModuleSlideshow from './AmpModules/AmpModuleSlideshow.vue';
import AmpModuleVideo from './AmpModules/AmpModuleVideo.vue';
import AmpGuestPostPremium from './AmpPremium/AmpGuestPostPremium.vue';
import AmpPremium from './AmpPremium/AmpPremium.vue';
import AmpFinancial from './AmpPremium/AmpFinancial.vue';
import AmpMsn from './AmpPremium/AmpMsn.vue';
import AmpCryptoPremium from './AmpPremium/AmpCryptoPremium.vue';
import AuthorizationMailSent from './AmpPremium/AuthorizationMailSent.vue';
import DateSelector from './DateSelector.vue';
import UpgradeCreditDialog from './Credits/UpgradeCreditDialog.vue';
import UseCreditDialog from './Credits/UseCreditDialog.vue';

import { UpgradeCreditsAction, UseCreditsAction } from '.';

import type { AmpModuleType, Announcement } from '@/types/Announcement';

import type {
    AppOAuth,
    PublishableModules,
    PublishData,
    PublishOptions
} from '.';

import { AAlert } from '@/components/AAlert';

@Component({
    components: {
        AmpDetails,
        DateSelector,
        AmpModules,
        AmpModuleVideo,
        AmpModuleSlideshow,
        AmpGuestPostPremium,
        AmpPremium,
        AmpFinancial,
        AmpMsn,
        AmpCryptoPremium,
        AmpTerms,
        StatusChip,
        AuthorizationMailSent,
        UpgradeCreditDialog,
        UseCreditDialog,
        AAlert
    }
})
export default class AmpPublish extends mixins(Endpoint) {
    announcement: Announcement | null = null;

    endpoint = '/announcements/publish';

    publishable: PublishableModules = {
        press_release: true,
        blog_post: false,
        infographic: false,
        podcast: false,
        slideshow: false,
        video: false
    };

    options: PublishOptions = {
        no_flash: true, // don't flash PHP errors, use new API instead
        is_premium: false,
        is_financial: false,
        is_msn: false,
        is_crypto_premium: false,
        is_guest_post_premium: false,

        min_distribution_time: '',

        publish_press_release: true,
        publish_blog_post: true,
        publish_podcast: true,
        publish_infographic: true,

        publish_slideshow: true,
        publish_slideshow_slideshare: false,
        use_user_slideshare_account: false,

        publish_video: true,
        publish_video_public: true,
        publish_video_ubc: true,
        publish_video_vimeo: true,
        publish_video_youtube: true,
        use_user_vimeo_account: false,
        use_user_youtube_account: false,
        upgrade_presscable_credits: null,
        has_upgraded: false
    };

    termsAccepted = false;

    oauths: AppOAuth[] = [];
    slideshareUser = '';

    isProcessingPayment = false;
    autoCheckPaymentInterval: ReturnType<typeof setInterval> | null = null;

    isPublishing = false;

    hasCryptoPremiumCredits = false;
    hasDistributionCredits = false;
    hasPremiumCredits = false;
    hasFinancialCredits = false;
    hasMsnCredits = false;
    hasPressCableCredits = false;
    guestPostPremiumCredits = 0;

    hasManyCompanies = false;

    isFirstFinancialAmp = false;

    guestPostUrl = '';

    hasPublishedGuestPost = false;

    useAdvanced = false;

    isLoaValid = false;
    isGuestPostUrlValid = false;
    tosError = false;

    date = this.$date().format('YYYY-MM-DD');

    time = this.$date().format('HH:mm');

    authorizationConfirmed = false;

    modulesPresent: PublishableModules = {
        press_release: false,
        blog_post: false,
        infographic: false,
        podcast: false,
        slideshow: false,
        video: false
    };

    confirmAuthorizationHook: null | ((sent: boolean) => void) = null;

    executeUpgradeCreditsHook: null | ((canContinue: boolean) => void) = null;

    executeUseCreditsHook: null | ((canContinue: boolean) => void) = null;

    get formattedDate() {
        return formatDate(this.date);
    }

    get formattedTime() {
        const [hour, minute] = this.time.split(':');

        if (!hour || !minute) {
            return '';
        }

        return this.$date()
            .set('hour', Number(hour))
            .set('minute', Number(minute))
            .format('LT');
    }

    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get blogPost() {
        return this.announcement?.blog_post || null;
    }

    get podcast() {
        return this.announcement?.podcast || null;
    }

    get infographic() {
        return this.announcement?.infographic || null;
    }

    get slideshow() {
        return this.announcement?.slideshow || null;
    }

    get videoPr() {
        return this.announcement?.video_pr || null;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.announcementId].join('/');
    }

    get isLimitedDistribution() {
        return Boolean(this.pressRelease?.limited_distribution_only);
    }

    get isCryptoCategory() {
        return this.announcement?.category_id === 30;
    }

    get hasNecessaryCredits() {
        if (
            this.announcement?.has_pending_status ||
            this.announcement?.has_published_status
        ) {
            return true;
        }

        const requirements = [
            this.hasDistributionCredits || this.hasUpgradableCredits
        ];

        if (
            this.options.is_premium &&
            (!this.announcement?.is_premium ?? false)
        ) {
            requirements.push(this.hasPremiumCredits);
        }

        if (
            this.options.is_financial &&
            (!this.announcement?.is_financial ?? false)
        ) {
            requirements.push(this.hasFinancialCredits);
        }

        if (this.options.is_msn && (!this.announcement?.is_msn ?? false)) {
            requirements.push(this.hasMsnCredits);
        }

        if (this.options.is_crypto_premium) {
            requirements.push(this.hasCryptoPremiumCredits);
        }

        if (this.options.is_guest_post_premium) {
            requirements.push(this.hasGuestPostPremiumCredits);
        }

        return requirements.every(Boolean);
    }

    get needsCheckout() {
        return (
            !this.isProcessingPayment &&
            !this.hasNecessaryCredits &&
            !this.isWebsiteMissing
        );
    }

    get isPublishable() {
        return (
            !this.isProcessingPayment &&
            this.hasNecessaryCredits &&
            !this.isWebsiteMissing
        );
    }

    get isWebsiteMissing() {
        return !this.announcement?.company?.website;
    }

    get canPublish() {
        const base =
            this.termsAccepted && !this.isPublishing && this.hasValidInput;

        if (this.options.is_financial) {
            return base && this.isLoaValid;
        }

        return base;
    }

    getPublishingErrorMessage() {
        if (!this.termsAccepted) {
            return 'Please review and accept the Terms of Service and Amp Guidelines';
        }

        if (this.options.is_financial && !this.isLoaValid) {
            return 'Please review Letter of Authorization.';
        }

        return 'Please review your submission.';
    }

    get shouldUpgradeCredits() {
        return !this.options.has_upgraded && this.hasUpgradableCredits;
    }

    get hasUpgradableCredits() {
        return this.hasPressCableCredits;
    }

    get hasAmpifireAndPresscableCredits() {
        return this.hasDistributionCredits && this.hasPressCableCredits;
    }

    get shouldConfirmAuthorization() {
        return (
            this.isPublishable &&
            this.isFirstFinancialAmpAndNoPending &&
            !this.authorizationConfirmed
        );
    }

    get isFirstFinancialAmpAndNoPending() {
        return this.options.is_financial && this.isFirstFinancialAmp;
    }

    get preparedOptions() {
        return {
            ...this.options,
            min_distribution_time: `${this.formattedDate} ${this.formattedTime}`
        };
    }

    get modulesNeedReview() {
        return Object.values(this.modulesPresent).includes(false);
    }

    get hasValidInput() {
        return this.options.is_guest_post_premium
            ? this.isGuestPostUrlValid
            : true;
    }

    get showReviewModuleMessage() {
        return (
            !this.isLoading &&
            this.modulesNeedReview &&
            !this.isLimitedDistribution
        );
    }

    get hasGuestPostPremiumCredits() {
        // see PROD-1904
        return Math.floor(this.guestPostPremiumCredits / 4) > 0;
    }

    activated() {
        // reset LoA state and let verification complete
        this.setLoaValid(false);
    }

    onMounted() {
        this.load().then(() => {
            // infer initial options
            this.setPublishableModules();

            this.updatePublishOptions();

            this.setModulePresence();

            if (this.$route.query.pay) {
                this.isProcessingPayment = true;

                this.autoCheckPaymentInterval = setInterval(
                    this.autoCheckForPayment.bind(this),
                    5000
                );
            }

            if (+this.$route.query.upgrade) {
                this.updateOptions({ has_upgraded: true });
            }

            if (+this.$route.query.premium) {
                this.updateOptions({ is_premium: true });
            }

            if (+this.$route.query.crypto_premium) {
                this.updateOptions({ is_crypto_premium: true });
            }

            if (+this.$route.query.financial) {
                this.updateOptions({ is_financial: true });
            }

            if (+this.$route.query.msn) {
                this.updateOptions({ is_msn: true });
            }

            if (+this.$route.query.guest_post_premium) {
                this.updateOptions({ is_guest_post_premium: true });
            }

            if (this.hasNecessaryCredits && this.modulesNeedReview) {
                this.useAdvanced = true;
            }
        });
    }

    beforeDestroy() {
        if (this.autoCheckPaymentInterval) {
            clearInterval(this.autoCheckPaymentInterval);
        }
    }

    onData(data: PublishData) {
        if (!data.publishable) {
            return this.review();
        }

        if (data.announcement) {
            this.announcement = data.announcement;

            this.hasManyCompanies = data.hasManyCompanies;

            this.setCredits(data);

            this.setApps(data);

            this.setPremium();

            this.setFinancial(data);

            this.setMsn(data);
        }
    }

    setApps(data: PublishData) {
        if (data.userSlideshareUsername) {
            this.slideshareUser = data.userSlideshareUsername;
        }

        this.setUserOauths(data.userOauths);
    }

    setUserOauths(userOauths: AppOAuth[]) {
        this.oauths = userOauths;
    }

    setCredits(data: PublishData) {
        this.hasCryptoPremiumCredits = data.hasCryptoPremiumCredits;
        this.hasDistributionCredits = data.hasDistributionCredits;
        this.hasPremiumCredits = data.hasPremiumCredits;
        this.hasFinancialCredits = data.hasFinancialCredits;
        this.hasMsnCredits = data.hasMsnCredits;
        this.hasPressCableCredits = data.hasPressCableCredits;
        this.guestPostPremiumCredits = data.guestPostPremiumCredits;

        this.isFirstFinancialAmp = data.isFirstFinancialAmp;
        this.guestPostUrl = data.guestPostUrl;
        this.hasPublishedGuestPost = data.hasPublishedGuestPost;

        if (this.isCryptoCategory && this.hasCryptoPremiumCredits) {
            // Crypto Distribution checked by default if credits available
            this.options.is_crypto_premium = true;
        }
    }

    setPremium() {
        this.updateOptions({
            is_premium: Boolean(
                this.pressRelease?.is_premium ||
                    (this.announcement?.is_premium ?? false)
            )
        });
    }

    setFinancial(data: PublishData) {
        this.updateOptions({
            is_financial: Boolean(
                data.isFinancial || (this.announcement?.is_financial ?? false)
            )
        });
    }

    setMsn(data: PublishData) {
        this.updateOptions({
            is_msn: Boolean(data.isMsn || (this.announcement?.is_msn ?? false))
        });
    }

    updatePublishOptions(publishable = this.publishable) {
        if (this.announcement) {
            this.updateOptions({
                publish_press_release: publishable.press_release,
                publish_blog_post: this.announcement.blog_post
                    ? publishable.blog_post
                    : false,
                publish_podcast: this.announcement.podcast
                    ? publishable.podcast
                    : false,
                publish_infographic: this.announcement.infographic
                    ? publishable.infographic
                    : false,
                publish_slideshow: this.announcement.slideshow
                    ? publishable.slideshow
                    : false,
                publish_video: this.announcement.video_pr
                    ? publishable.video
                    : false
            });
        }
    }

    setPublishableModules() {
        if (this.announcement) {
            this.publishable.press_release = Boolean(
                this.announcement.press_release
            );
            this.publishable.blog_post = this.isModulePublishable('blog_post');
            this.publishable.podcast = this.isModulePublishable('podcast');
            this.publishable.infographic =
                this.isModulePublishable('infographic');
            this.publishable.slideshow = this.isModulePublishable('slideshow');
            this.publishable.video = this.isModulePublishable('video_pr');
        }
    }

    isModulePublishable(module: AmpModuleType): boolean {
        if (this.announcement) {
            if (module !== 'press_release' && this.announcement[module]) {
                return Boolean(
                    !this.isLimitedDistribution &&
                        this.announcement[module]?.is_publishable
                );
            }

            return Boolean(this.announcement[module]?.is_publishable);
        }

        return false;
    }

    async process() {
        if (!this.canPublish) {
            this.$store.dispatch(
                'notification/error',
                this.getPublishingErrorMessage()
            );
            this.tosError = true;
            return false;
        }

        if (this.shouldConfirmAuthorization) {
            this.authorizationConfirmed = await this.askForAuthorization();

            if (!this.authorizationConfirmed) {
                return false;
            }
        }

        if (this.shouldUpgradeCredits) {
            const canContinue = await this.askForCreditsUpgrade();

            if (!canContinue) {
                return false;
            }
        }

        return this.publish();
    }

    async publish() {
        this.isPublishing = true;

        this.resetFormCache();

        return this.$http
            .post(
                `/announcements/publish_amp/${this.announcementId}`,
                this.preparedOptions
            )
            .then(({ data }) => {
                if (data.data.success) {
                    this.onSuccess(data.data);
                } else {
                    this.onNeedAttention(data.data);
                }
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            })
            .finally(() => {
                this.isPublishing = false;
            });
    }

    autoCheckForPayment() {
        this.getData(true).then(({ data }) => {
            if (data) {
                this.setCredits(data);

                if (this.hasDistributionCredits && this.creditsReady()) {
                    if (this.autoCheckPaymentInterval) {
                        clearInterval(this.autoCheckPaymentInterval);
                    }

                    this.isProcessingPayment = false;

                    this.$store.dispatch(
                        'notification/success',
                        'Payment received. You may continue and publish.'
                    );
                }
            }
        });
    }

    creditsReady() {
        const premiumCreditsUpdatedIfNeeded =
            !this.options.is_premium ||
            (this.options.is_premium && this.hasPremiumCredits);

        const cryptoPremiumCreditsUpdatedIfNeeded =
            !this.options.is_crypto_premium ||
            (this.options.is_crypto_premium && this.hasCryptoPremiumCredits);

        const financialCreditsUpdatedIfNeeded =
            !this.options.is_financial ||
            (this.options.is_financial && this.hasFinancialCredits);

        const msnCreditsUpdatedIfNeeded =
            !this.options.is_msn || (this.options.is_msn && this.hasMsnCredits);

        const guestPostPremiumCreditsUpdatedIfNeeded =
            !this.options.is_guest_post_premium ||
            (this.options.is_guest_post_premium &&
                this.hasGuestPostPremiumCredits);

        return (
            premiumCreditsUpdatedIfNeeded &&
            cryptoPremiumCreditsUpdatedIfNeeded &&
            financialCreditsUpdatedIfNeeded &&
            msnCreditsUpdatedIfNeeded &&
            guestPostPremiumCreditsUpdatedIfNeeded
        );
    }

    onSuccess({ redirect }: { redirect?: string }) {
        if (redirect) {
            // payment page
            window.location.href = redirect;
        } else {
            this.$router.push('/announcements', () => {
                this.$store.dispatch(
                    'notification/success',
                    'Amp Submitted. Watch out for notifications via email or on this page.'
                );
            });
        }
    }

    onNeedAttention({ module, errors }: { module: string; errors: string[] }) {
        const header = [
            'Amp content',
            'is not valid for publishing.',
            ' Please revise the following:'
        ];

        switch (module) {
            case 'article': {
                header[0] = 'News Article';
                break;
            }
            case 'blog-post': {
                header[0] = 'Blog post content';
                break;
            }
            case 'podcast': {
                header[0] = 'Podcast content';
                break;
            }
            case 'infographic': {
                header[0] = 'Infographic content';
                break;
            }
            case 'slideshow': {
                header[0] = 'Slideshow content';
                break;
            }
            case 'video': {
                header[0] = 'Video content';
                break;
            }
            // no default
        }

        const messages = new Set<string>();

        reduceErrors(errors, messages);

        this.$store.dispatch('broadcast/error', {
            source: `${this.announcementId}-publish-${module}`,
            clean: true, // every new publish should reset previous attempt results
            message: [header.join(' '), [...messages].join('<br/>')]
                .filter(Boolean)
                .join('<br/>')
        });

        this.$router.push(
            `/announcements/edit/${this.announcementId}/${module}`
        );
    }

    updatePublishDate({ date, time }: { date?: string; time?: string }) {
        if (date) {
            this.date = date;
        }

        if (time) {
            this.time = time;
        }
    }

    updateOptions(settings: Partial<PublishOptions> = {}) {
        this.options = { ...this.options, ...settings };
    }

    updateFinancial(settings: Partial<PublishOptions> = {}) {
        this.setLoaValid(false);

        this.updateOptions(settings);
    }

    setTermsAccepted(isAccepted = false) {
        this.termsAccepted = isAccepted;
    }

    onTermsCollapse() {
        this.setTermsAccepted();
    }

    setLoaValid(isValid = false) {
        this.isLoaValid = isValid;
    }

    setGuestPostUrlValid(isValid = false) {
        this.isGuestPostUrlValid = isValid;
    }

    review() {
        this.resetFormCache();

        this.$router.push(`/announcements/review/${this.announcementId}`);
    }

    resetFormCache() {
        this.$store.dispatch(
            'cachekey/reset',
            this.$route.params.announcementId
        );
    }

    async askForAuthorization(): Promise<boolean> {
        return new Promise(resolve => {
            this.confirmAuthorizationHook = wasSent => {
                resolve(wasSent);
            };

            this.$store.dispatch('modal/open', 'authorization-mail-sent');
        });
    }

    confirmAuthorization(sent: boolean) {
        if (typeof this.confirmAuthorizationHook === 'function') {
            this.confirmAuthorizationHook(sent);
        }
    }

    async askForCreditsUpgrade(): Promise<boolean> {
        return new Promise(resolve => {
            this.executeUpgradeCreditsHook = canContinue => {
                resolve(canContinue);
            };

            this.$store.dispatch('modal/open', 'upgrade-credit-dialog');
        });
    }

    upgradeCreditsContinue(canContinue = false) {
        if (typeof this.executeUpgradeCreditsHook === 'function') {
            return this.executeUpgradeCreditsHook(canContinue);
        }
    }

    async askForCreditToUse(): Promise<boolean> {
        return new Promise(resolve => {
            this.executeUseCreditsHook = canContinue => {
                resolve(canContinue);
            };

            this.$store.dispatch('modal/open', 'use-credit-dialog');
        });
    }

    useCreditsContinue(canContinue = false) {
        if (typeof this.executeUseCreditsHook === 'function') {
            return this.executeUseCreditsHook(canContinue);
        }
    }

    async upgradeCredits(action: UpgradeCreditsAction) {
        let canContinue = true;

        switch (action) {
            case UpgradeCreditsAction.DontUpgrade: {
                if (this.hasAmpifireAndPresscableCredits) {
                    canContinue = await this.askForCreditToUse();
                } else {
                    this.setCreditsForUpgrade(0); // 0 means "use PressCable credits"
                    this.publishNewsArticleOnly();
                }

                break;
            }

            case UpgradeCreditsAction.UpgradeOne: {
                this.setCreditsForUpgrade(1);

                break;
            }

            case UpgradeCreditsAction.UpgradeAll: {
                this.setCreditsForUpgrade(Number.MAX_SAFE_INTEGER);

                break;
            }
            // no default
        }

        this.upgradeCreditsContinue(canContinue);
    }

    useCredits(action: UseCreditsAction) {
        if (action === UseCreditsAction.UseAmpiFire) {
            this.setCreditsForUpgrade(null); // null means "use AmpiFire credits"
        } else if (action === UseCreditsAction.UsePressCable) {
            this.setCreditsForUpgrade(0);
            this.publishNewsArticleOnly();
        }

        this.useCreditsContinue(true);
    }

    publishNewsArticleOnly() {
        this.updateOptions({
            publish_blog_post: false,
            publish_podcast: false,
            publish_infographic: false,
            publish_slideshow: false,
            publish_video: false
        });
    }

    setCreditsForUpgrade(count: number | null) {
        this.updateOptions({
            upgrade_presscable_credits: count
        });
    }

    setModulePresence() {
        if (this.announcement) {
            this.modulesPresent.press_release = Boolean(
                this.announcement.press_release
            );
            this.modulesPresent.blog_post = Boolean(
                this.announcement.blog_post
            );
            this.modulesPresent.podcast = Boolean(this.announcement.podcast);
            this.modulesPresent.slideshow = Boolean(
                this.announcement.slideshow
            );
            this.modulesPresent.infographic = Boolean(
                this.announcement.infographic
            );
            this.modulesPresent.video = Boolean(this.announcement.video_pr);
        }
    }
}
</script>

<style lang="scss">
.amp-publish {
    .v-label:not(.v-label--is-disabled) {
        color: $secondary-color;
    }
}
</style>
