import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pa-0"},[_c(VDivider,{staticClass:"mb-6"}),_c(VRow,[_c(VCol,[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Video Settings")]),_c(VCheckbox,{staticClass:"mb-3",attrs:{"label":"Make video available for anyone to view","hide-details":""},model:{value:(_vm.settings.publish_video_public),callback:function ($$v) {_vm.$set(_vm.settings, "publish_video_public", $$v)},expression:"settings.publish_video_public"}}),_c(VRow,{staticClass:"align-end"},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"6","sm":"4"}},[_c(VCheckbox,{attrs:{"label":"Distribute on Youtube","hide-details":""},model:{value:(_vm.settings.publish_video_youtube),callback:function ($$v) {_vm.$set(_vm.settings, "publish_video_youtube", $$v)},expression:"settings.publish_video_youtube"}})],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"6","sm":"4"}},[(_vm.settings.publish_video_youtube)?_c(VSwitch,{attrs:{"inset":"","label":"Use my own account","hide-details":""},model:{value:(_vm.settings.use_user_youtube_account),callback:function ($$v) {_vm.$set(_vm.settings, "use_user_youtube_account", $$v)},expression:"settings.use_user_youtube_account"}}):_vm._e()],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","sm":"4"}},[(
                            _vm.settings.use_user_youtube_account &&
                            _vm.settings.publish_video_youtube
                        )?_c(VChip,{staticClass:"pr-2",attrs:{"small":"","close":"","color":"faded-blue","close-icon":_vm.services.youtube.user ? 'pen' : 'plus-circle'},on:{"click:close":function($event){return _vm.connectApp('youtube')}}},[_vm._v(" "+_vm._s(_vm.services.youtube.user ? _vm.services.youtube.user.name : 'Add YouTube Account')+" ")]):_vm._e(),(
                            _vm.settings.use_user_youtube_account &&
                            _vm.settings.publish_video_youtube
                        )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.services.youtube.loading},on:{"click":function($event){$event.preventDefault();return _vm.refreshApp('youtube')}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("sync")])],1)]}}],null,false,3989350667)},[_c('span',[_vm._v("Refresh Connected YouTube Account")])]):_vm._e()],1),_c('connect-youtube',{on:{"connected":function($event){return _vm.refreshApp('youtube')}}})],1),_c(VRow,{staticClass:"align-end mt-0"},[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"6","sm":"4"}},[_c(VCheckbox,{attrs:{"label":"Distribute to Vimeo","hide-details":""},model:{value:(_vm.settings.publish_video_vimeo),callback:function ($$v) {_vm.$set(_vm.settings, "publish_video_vimeo", $$v)},expression:"settings.publish_video_vimeo"}})],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"6","sm":"4"}},[(_vm.settings.publish_video_vimeo)?_c(VSwitch,{attrs:{"inset":"","label":"Use my own account","hide-details":""},model:{value:(_vm.settings.use_user_vimeo_account),callback:function ($$v) {_vm.$set(_vm.settings, "use_user_vimeo_account", $$v)},expression:"settings.use_user_vimeo_account"}}):_vm._e()],1),_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12","sm":"4"}},[(
                            _vm.settings.publish_video_vimeo &&
                            _vm.settings.use_user_vimeo_account
                        )?_c(VChip,{staticClass:"pr-2",attrs:{"small":"","close":"","color":"faded-blue","close-icon":_vm.services.vimeo.user ? 'pen' : 'plus-circle'},on:{"click:close":function($event){return _vm.connectApp('vimeo')}}},[_vm._v(" "+_vm._s(_vm.services.vimeo.user ? _vm.services.vimeo.user.name : 'Add Vimeo Account')+" ")]):_vm._e(),(
                            _vm.settings.publish_video_vimeo &&
                            _vm.settings.use_user_vimeo_account
                        )?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.services.vimeo.loading},on:{"click":function($event){$event.preventDefault();return _vm.refreshApp('vimeo')}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("sync")])],1)]}}],null,false,617791787)},[_c('span',[_vm._v("Refresh Connected Vimeo Account")])]):_vm._e()],1),_c('connect-vimeo',{on:{"connected":function($event){return _vm.refreshApp('vimeo')}}})],1),_c(VCheckbox,{attrs:{"label":"Distribute to UBC News World","hide-details":""},model:{value:(_vm.settings.publish_video_ubc),callback:function ($$v) {_vm.$set(_vm.settings, "publish_video_ubc", $$v)},expression:"settings.publish_video_ubc"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }